export const FirebaseConfig = {
	"projectId": "angata-taxi",
	"appId": "1:618908409316:web:a28c75bfc2a01574da7bf1",
	"databaseURL": "https://angata-taxi-default-rtdb.firebaseio.com",
	"storageBucket": "angata-taxi.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyD5RoHwQotoyYru2lK9Iri88wAM-0fLiew",
	"authDomain": "angata-taxi.firebaseapp.com",
	"messagingSenderId": "618908409316",
	"measurementId": "G-6LMFHXDDT8"
};